<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      {{ $t('Warehouse List') }}
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-fulfillment-warehouses-create' }"
      back-to-list-path="settings-fulfillment-warehouses-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/trashList`,
      }"
    >
      <template #cell(address)="{ data }">
        {{ data.item.street }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip_code }}
      </template>

      <template #cell(role)="{ data }">
        {{ data.value.name }}
      </template>
      <template #cell(status)="{ data }">
        {{ data.value ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Delete')"
            icon="LTrashIcon"
            size="30"
            style="padding: 4px"
            class="cursor-pointer border-dotted featherIcon mr-1"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip } from 'bootstrap-vue'
import tableConfig from '../warehouseConfig'

export default {
  name: 'WarehouseList',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchWarehouseLocations() {
      this.$store.dispatch('horizontalMenu/getWarehousesList')
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        }).finally(() => {
          this.refetchWarehouseLocations()
        })
    },
    remove(data) {
      this.confirmNotification(this, { id: data.id, data }, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      }).finally(() => {
        this.refetchWarehouseLocations()
      })
    },
  },
  setup() {
    const MODULE_NAME = 'warehouse'

    const { tableColumns } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>
