var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"},[_vm._v(" "+_vm._s(_vm.$t('Warehouse List'))+" ")]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-fulfillment-warehouses-create' },"back-to-list-path":"settings-fulfillment-warehouses-list","table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/trashList"),
    }},scopedSlots:_vm._u([{key:"cell(address)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.street)+", "+_vm._s(data.item.city)+", "+_vm._s(data.item.state.name)+" "+_vm._s(data.item.zip_code)+" ")]}},{key:"cell(role)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value.name)+" ")]}},{key:"cell(status)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value ? 'Active' : 'Inactive')+" ")]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer border-dotted featherIcon mr-1",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Delete'),"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"featherIcon cursor-pointer border-dotted",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreContact(data.item.id)}}})],1)]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }